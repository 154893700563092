<!-- @format -->

<template>
  <div class="preview">
    <div class="py-2 px-2.5">
      <div
        class="flex justify-center text-center border-2 border-grey-700 p-5 rounded-lg text-sm overflow-x-auto overflow-y-auto bg-gray-200"
      >
        {{ props.desc }}
      </div>
    </div>
    <div id="frame" ref="frameRef" class="rounded-md" />
    <iframe ref="iframe" :srcdoc="code" width="100%" height="100%" />
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  codeFromBox: {
    type: String,
    required: true,
  },
  desc: {
    type: String,
    required: true,
  },
});

const desc = ref(props.desc);
const code = ref(props.code);

watch(
  () => props.codeFromBox,
  (newValue, oldValue) => {
    if ((oldValue = null)) {
      code.value = codeFromBox.value;
    }
    code.value = newValue;
  },
  { deep: true }
);
</script>

<style>
.preview {
  height: 85vh;
  width: 35vw;
}

iframe {
  border: none;
}
</style>
