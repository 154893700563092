<script setup>
import "prismjs/themes/prism.css";
import { defineProps, ref } from "vue";
import { Codemirror } from "vue-codemirror";
import { html } from "@codemirror/lang-html";
import { oneDark } from "@codemirror/theme-one-dark";
import { EditorView } from "codemirror";

const emits = defineEmits(["updateIframeContent"]);
const codeFromBox = ref(props.code);

const extensions = [html(), oneDark, EditorView.lineWrapping];
const props = defineProps({
  code: {
    type: String,
    required: true,
  },
});

const updateIframeContent = () => {
  emits("updateIframeContent", codeFromBox.value);
};
</script>

<template>
  <div class="h-full flex flex-col gap-2">
    <Codemirror
      v-model="codeFromBox"
      :model-value="props.code"
      placeholder="Code goes here..."
      :style="{
        height: '95%',
        width: '40vw',
        borderRadius: '10px',
        overflow: 'hidden',
      }"
      :autofocus="true"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="extensions"
    />
    <div
      class="bg-orange-400 text-white rounded-md py-2 px-2.5 self-center hover:bg-orange-500"
    >
      <button @click="updateIframeContent">Refresh plugin</button>
    </div>
  </div>
</template>

<style scoped></style>
