const options = [
  {
    id: 0,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle Plugin - Example</title>
	  </head>
	  <body>
		  <div data-pym-src='https://www.jdoodle.com/plugin' data-language="java" data-version-index="4" data-libs="org.apache.commons:commons-lang3:3.4">
	public class MyClass {
		public static void main(String args[]) {
			int x=10;
			int y=25;
			int z=x+y;

			System.out.println("Sum of x+y = " + z);
		}
	}
		</div>
		<script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	  </body>
	</html>
	`,
    desc: "Plug and play JDoodle IDE in your application",
  },
  {
    id: 1,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle-Embed</title>
	  </head>
	  <body>
		<div data-pym-src='https://stage.jdoodle.com/embed/linkcors-html' data-hide-js="false" data-hide-js-lib="false">
			<div data-script-type="html">
	&lt;div class="welcome"&gt;Welcome To JDoodle.com&lt;/div&gt;
			</div>
			<div data-script-type="css">
	.welcome {
		color:green;
		font-size:36px;
		font-family:cursive;
		text-align:center;
		padding:20px;
	}
			</div>
			<div data-script-type="js">
	$(document).ready(function() {
		$(".welcome").append("<span>!!!</span>");
	});
			</div>
			<div data-script-type="jslib">
	&lt;script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.1/jquery.min.js">&lt;/script>
			</div>
		</div>
		<script src="https://stage.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	  </body>
	</html>
	`,
    desc: "Using JDoodle, developers can seemlessly work on the web applications for quick turnarounds",
  },
  {
    id: 2,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle-Embed</title>
	  </head>
	  <body>
		<div data-pym-src='https://stage.jdoodle.com/embed/linkcors-db' data-language="phpdblink" data-version-index="0">
	<div data-script-type="db">
		Create Database mydb;
		Use mydb;
		CREATE TABLE customers (name VARCHAR(255), address VARCHAR(255));
		insert into customers values('JDoodle', 'Australia');
	</div>
	<div data-script-type="program"><xmp><?php
		$servername = "localhost";
		$username = "root";
		$password = "password123";
		$dbname = "mydb";

		// Create connection
		$conn = mysqli_connect($servername, $username, $password, $dbname);

		// Check connection
		if (!$conn) {
			die("Connection failed: " . mysqli_connect_error());
		}
		echo "Connected successfully";

		$result = mysqli_query($conn,"SELECT * FROM customers");

		while($row = mysqli_fetch_array($result)) {
			echo $row[0]."-".$row[1];
		}
	?>
	</xmp>
	</div>
		</div>
		<script src="https://stage.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	  </body>
	</html>
	`,
    desc: "Developers can connect to database using PHP without any hassle.",
  },
  {
    id: 3,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle-Embed</title>
	  </head>
	  <body>
		<div data-pym-src='https://stage.jdoodle.com/embed/linkcors-multi-db' data-language="javadblink" data-version-index="0" data-libs="mysql:mysql-connector-java:8.0.26" data-override-home="true" data-home="/MyClass.java">
	<div data-script-type="db">
		Create Database mydb;
		Use mydb;
		CREATE TABLE customers (name VARCHAR(255), address VARCHAR(255));
		insert into customers values('JDoodle', 'Australia');
			insert into customers values('JDoodle', 'Turkey');
	</div>

	<div data-script-name="/MyClass.java">
	import com.jdoodle.util.MyUtil;

	public class MyClass {
		public static void main(String args[])  {
			new MyUtil().listCustomers();
		}
	}</div>
	<div data-script-name="/com/jdoodle/util/MyUtil.java">
	package com.jdoodle.util;
	import java.sql.*;

	public class MyUtil {
		public void listCustomers()  {
			try{
				Connection con=DriverManager.getConnection(
				"jdbc:mysql://localhost:3306/mydb","root","password123");
				Statement stmt=con.createStatement();
				ResultSet rs=stmt.executeQuery("select * from customers");
				while(rs.next())
				System.out.println(rs.getString(1)+"  "+rs.getString(2));
				con.close();
			} catch(Exception e){
				System.out.println(e);
			}
		}
	}</div>
		</div>
	  <script src="https://stage.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	  </body>
	</html>
	`,
    desc: "You can load your java project and work without worrying about setting up your IDE and environment.",
  },
  {
    id: 4,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle-Embed</title>
	  </head>
	  <body>
		<div data-pym-src='https://stage.jdoodle.com/embed/linkcors' data-language="java" data-version-index="3"  data-libs="">
	</div>
		<script src="https://stage.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	  </body>
	</html>
	`,
    desc: "Nothing just code. Clean Java IDE",
  },
  {
    id: 5,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle-Embed</title>
	  </head>
	  <body>
		<div data-pym-src='https://stage.jdoodle.com/embed/linkcors-multi'  data-language="htmlphp">
			<div data-script-name="/index.php">&lt;html&gt;
	&lt;head&gt;&lt;/head&gt;
	&lt;body&gt;
		&lt;div class="welcome"&gt;
			Welcome To JDoodle.com
		&lt;/div&gt;
		&lt;?php echo '&lt;p&gt;Hello World from php&lt;/p&gt;'; ?&gt;
		&lt;a href='test/test1/test2/test2/index.php'&gt;Goto Another Page&lt;/a&gt;
		&lt;script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.1/jquery.min.js"&gt;&lt;/script&gt;
		&lt;script src="js/script.js"&gt;&lt;/script&gt;
		&lt;link rel="stylesheet" href="css/style.css"/&gt;
	&lt;/body&gt;
&lt;/html&gt;
			</div>
			<div data-script-name="/another.html">
				&lt;div class="welcome"&gt;Welcome To JDoodle.com&lt;/div&gt;
			</div>
			<div data-script-name="/test/test1/test2/test2/index.php">
				&lt;div class="welcome"&gt;Welcome To the another test page.&lt;/div&gt;
			</div>
			<div data-script-name="/css/style.css">
	.welcome {
		color:green;
		font-size:36px;
		font-family:cursive;
		text-align:center;
		padding:20px;
	}
			</div>
			<div data-script-name="/js/script.js">
	$(document).ready(function() {
		$(".welcome").append("<span>!!!</span>");
	});
			</div>
		</div>
	  <script src="https://stage.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	  <script type="text/javascript">
		function mycallback(script, output) {

		}
	  <\/script>
	  </body>
	</html>
	`,
    desc: "Build full stack applications using PHP using JDoodle. Easy and fast.",
  },
  {
    id: 6,
    htmlCode: `<html>
	<body>
	<div data-pym-src="https://www.jdoodle.com/embed/v0/4pEV?stdin=0&arg=0&rw=1"></div>
		<script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script>
	</body>
	</html>
	`,
    desc: "Just need a plain simple IDE for your application, go ahead and use the very basic version of Embed plugin.",
  },
  {
    id: 7,
    htmlCode: `<!DOCTYPE html>
	<html lang="en">
	  <head>
		<title>JDoodle-Embed</title>
	  </head>
	  <body>
		<div
		  data-pym-src="https://gui.jdoodle.com/embed/linkcors-multi"
		  data-language="java"
		  data-version-index="3"
		  data-override-home="true"
		  data-home="/Hello.java"
		>
		  <div data-script-name="/Hello.java">import java.awt.FlowLayout; 
import javax.swing.JButton;
import javax.swing.JFrame; 
import javax.swing.JLabel;
import javax.swing.JPanel;
			
public class Hello {
	public static void main(String s[]) {
		JFrame frame = new JFrame("JFrame Example");
		JPanel panel = new JPanel();
		panel.setLayout( new FlowLayout());
		JLabel label = new JLabel("JFrame By Example");
		JButton button = new JButton();
		button.setText("Button");
		panel.add(label);
		panel.add(button);
		frame.add(panel);
		frame.setSize(200, 300);
		frame.setLocationRelativeTo(null);
		frame.setDefaultCloseOperation(JFrame.EXIT_ON_CLOSE);
		frame.setVisible(true);
	}
}
		  </div>
		  <div data-script-name="/com/jdoodle/util/MyUtil.java">package com.jdoodle.util;
	public class MyUtil { 
		public void sayAHello() { 
			System.out.println( "Hello World!!! - Test" ); 
		} 
	}
		  </div>
		</div>
		<script
		  src="https://gui.jdoodle.com/assets/jdoodle-pym.min.js"
		  type="text/javascript"
		></script>
	  </body>
	</html>
	`,
    desc: "Java IDE with GUI along with directory tree view to work with GUI Java project at the same time",
  },
];

export default options;
