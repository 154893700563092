<!-- @format -->

<script setup>
import SideBar from "@/components/SideBar.vue";
import HTMLCode from "@/components/HTMLCode.vue";
import CodeCompletion from "@/components/CodeCompletion.vue";
import Demo from "@/components/Demo.vue";
import CodeGenerate from "@/components/CodeGenerate.vue";
import options from "@/data/data";
import { ref, watch } from "vue";
import AssignmentForm from "@/components/AssignmentForm.vue";
const optionId = ref(0);
const showIDE = ref(false);
const showAssignment = ref(false);
const showCodeCompletion = ref(false);
const showFeedbackForm = ref(false);
const taskType = ref("");

const code = ref(options[optionId.value].htmlCode);
const codeFromBox = ref("");
const desc = ref("Plug and play JDoodle IDE in your application.");
const codeIDE = ref(`//
// This is a sample code
//
public static int fibonacci(int n) {
    if (n <= 1) {
        return n;
    } else {
        return fibonacci(n - 1) + fibonacci(n - 2);
    }
}
`);
const descIDE = ref("AI - Code Generation");

watch(
  () => optionId.value,
  (newOption, oldOption) => {
    code.value = options[optionId.value].htmlCode;
    codeFromBox.value = options[optionId.value].htmlCode;
    desc.value = options[optionId.value].desc;
  }
);

const updateOptionId = (selectedOption) => {
  showAssignment.value = false;
  showIDE.value = false;
  showFeedbackForm.value = false;

  optionId.value = selectedOption;
};

const onShowAssignment = (flag) => {
  showIDE.value = false;
  showCodeCompletion.value = false;
  showFeedbackForm.value = false;

  showAssignment.value = flag;
};
const onShowIde = (flag) => {
  showAssignment.value = false;
  showCodeCompletion.value = false;
  showFeedbackForm.value = false;
  showIDE.value = flag;
};

const onShowFeedbackForm = (flag) => {
  showIDE.value = false;

  showAssignment.value = false;
  showFeedbackForm.value = flag;
  showCodeCompletion.value = false;
};

const onShowCodeCompletion = (flag, task) => {
  showCodeCompletion.value = flag;
  taskType.value = task;
  showIDE.value = false;

  showAssignment.value = false;
  showFeedbackForm.value = false;
};

const onCodeChange = (code) => {
  codeFromBox.value = code;
};
</script>

<template>
  <div class="bg-gray-50 dark:bg-slate-900 h-full">
    <div class="flex gap-4 h-full justify-start">
      <div class="h-full">
        <SideBar
          @selectOption="updateOptionId"
          @showIde="onShowIde"
          @showAssignment="onShowAssignment"
          @showFeedbackForm="onShowFeedbackForm"
          @showCodeCompletion="onShowCodeCompletion"
        />
      </div>
      <div class="flex justify-center w-full gap-8 py-10">
        <div
          id="HTML"
          v-if="
            !showIDE &&
            !showAssignment &&
            !showFeedbackForm &&
            !showCodeCompletion
          "
          class="h-full"
        >
          <HTMLCode :code="code" @updateIframeContent="onCodeChange" />
        </div>
        <div id="DEMO" class="">
          <Demo
            v-if="
              !showIDE &&
              !showAssignment &&
              !showFeedbackForm &&
              !showCodeCompletion
            "
            :code="code"
            :code-from-box="codeFromBox"
            :desc="desc"
          />
        </div>
        <div
          v-if="showIDE && !showAssignment && !showCodeCompletion"
          class="w-[100%] h-full"
        >
          <CodeGenerate :codeIDE="codeIDE" :descIDE="descIDE" />
        </div>
        <div
          v-if="showCodeCompletion && !showAssignment && !showIDE"
          class="grow"
        >
          <CodeCompletion :taskType="taskType" />
        </div>
        <div
          v-if="showAssignment && !showIDE && !showCodeCompletion"
          class="w-full"
        >
          <AssignmentForm />
        </div>
        <div
          v-if="showFeedbackForm && !showIDE && !showAssignment"
          class="w-[80%] h-[80%] mt-1rem"
        >
          <iframe
            class="border-3 rounded-md border-gray-400"
            frameborder="0"
            style="height: 700px; width: 100%; border: none"
            src="https://forms.zohopublic.com.au/hello59/form/JDoodleFeedbackForm/formperma/KU_0mr7eZFmafCR-iUL5-YWED5Xqj7bKDtsVhbldEus"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
