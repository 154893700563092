<!-- @format -->

<script setup>
import { defineEmits, ref } from "vue";

const activeId = ref(null);

const emits = defineEmits([
  "selectOption",
  "showIde",
  "showAssignment",
  "showFeedbackForm",
  "showCodeCompletion",
]);

const selectOption = (option) => {
  emits("selectOption", option);
  activeId.value = option;
};
const showCodeCompletion = (flag, task) => {
  emits("showCodeCompletion", flag, task);
};

const showAssignment = (flag) => {
  emits("showAssignment", flag);
};
const showIde = (flag) => {
  emits("showIde", flag);
};

const showFeedbackForm = (flag) => {
  emits("showFeedbackForm", flag);
};
</script>
<template>
  <div
    class="h-full w-72 px-6 pt-7 pb-10 m-0 transition-all duration-300 transform hidden top-0 left-0 right-1 bg-white border-r border-gray-200 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0"
  >
    <div class="">
      <a
        class="inline-flex items-center gap-x-2 text-xl font-semibold"
        href="#"
      >
        <img
          class="w-10 h-auto rounded-full"
          src="../assets/logo.png"
          alt="Logo"
        />
        JDoodle - Demo
      </a>
    </div>
    <nav class="hs-accordion-group w-full flex flex-col">
      <ul class="space-y-3.5">
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 0 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(0);
                showIde(false);
              }
            "
          >
            Java Simple
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 1 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(1);
                showIde(false);
              }
            "
          >
            HTML Simple
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 2 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(2);
                showIde(false);
              }
            "
          >
            Php with Database
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 3 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(3);
                showIde(false);
              }
            "
          >
            Java DB multifile
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 4 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(4);
                showIde(false);
              }
            "
          >
            Java Empty Stage
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 5 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(5);
                showIde(false);
              }
            "
          >
            PHP HTML
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 6 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(6);
                showIde(false);
              }
            "
          >
            Embed plugin
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 7 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(7);
                showIde(false);
              }
            "
          >
            JD GUI Embed
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 8 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              showIde(true);
              activeId = 8;
            "
          >
            AI Code Generation
            <span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 9 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'complete');
              activeId = 9;
            "
          >
            AI Code Completion<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 10 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'optimize');
              activeId = 10;
            "
          >
            AI Code Optimize<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 11 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'comment');
              activeId = 11;
            "
          >
            AI Code Comment<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 12 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'translate');
              activeId = 12;
            "
          >
            AI Code Translate<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 13 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'debug');
              activeId = 13;
            "
          >
            AI Code Debug<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 14 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showAssignment(true);
              activeId = 14;
            "
          >
            AI Question Generation<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-orange-400 text-sm text-white rounded-md hover:bg-orange-500"
            :class="activeId == 15 ? 'jdoodle-orange text-white' : ''"
            href="#"
            @click="
              showFeedbackForm(true);
              activeId = 11;
            "
          >
            Feedback
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<style scoped>
/* .jdoodle-orange {
  background-color: #fd5200;
} */
</style>
